<template>
  <div>
    <b-card class="mb-5">
      <b-embed
        type="iframe"
        aspect="4by3"
        src="https://whatsapp-api-mark4.herokuapp.com/"
        allowfullscreen
      />
    </b-card>
  </div>
</template>

<script>
import { BEmbed, BCard } from 'bootstrap-vue'

export default {
  components: {
    BEmbed,
    BCard,
  },
}
</script>
